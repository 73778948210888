import '../App.css';
import { Component } from 'react';

class Projects extends Component {
    render() {
        return (
            <h1>PROJECTS</h1>
        );
    }
}

export default Projects;