import '../App.css';
import { Component } from 'react';

class Resume extends Component {
    render() {
        return (
            <h1>RESUME</h1>
        );
    }
}

export default Resume;