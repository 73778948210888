import '../App.css';
import { Component } from 'react';
import $ from 'jquery';

class Home extends Component {

    render() {
        return (
            <div id="Home">
                <h1>HOME</h1>
            </div>
        );
    }
    
    componentDidMount() {
        $('.active').removeClass('active');
    }
}

export default Home;